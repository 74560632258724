import React from 'react';

const RainbowAnimatedText = ({ text, className = '', style = {} }) => {
  return (
    <span className={`rainbow-animated-text ${className}`} style={style}>
      {text.split('').map((char, index) => (
        char === ' ' ? 
          <span key={index}>&nbsp;</span> : 
          <span key={index} style={{ animationDelay: `${index * 0.1}s` }}>{char}</span>
      ))}
      <style jsx>{`
        @keyframes rainbow-animation {
          0% { color: #FF0000; } /* Red */
          16.67% { color: #FF8E00; } /* Orange */
          33.33% { color: #FFEE00; } /* Yellow */
          50% { color: #008E00; } /* Green */
          66.67% { color: #00C0FF; } /* Blue */
          83.33% { color: #8A2BE2; } /* Purple */
          100% { color: #FF0000; } /* Back to Red */
        }
        .rainbow-animated-text span:not(:empty) {
          display: inline-block;
          animation: rainbow-animation 20s linear infinite;
        }
      `}</style>
    </span>
  );
};

export default RainbowAnimatedText;