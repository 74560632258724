import React from 'react';
import './TopLinks.css';

export default function TopLinks() {
  return (
    <div className="TopLinks">
      <a href="https://dexscreener.com/" className="TopLinkContainer" target="_blank" rel="noopener noreferrer">
        <span className="rotate-text">CHART</span>
      </a>
      <a href="https://t.me/wokigayportal" className="TopLinkContainer" target="_blank" rel="noopener noreferrer">
        <span className="rotate-text">TELEGRAM</span>
      </a>
      <a href="https://x.com/woki_sol" className="TopLinkContainer" target="_blank" rel="noopener noreferrer">
        <span className="rotate-text">X</span>
      </a>
    </div>
  );
}