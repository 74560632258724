import React, { useEffect, useRef } from 'react';
import './About.css';
import avatar1 from '../assets/avatar1.png';
import avatar2 from '../assets/avatar2.png';
import avatar3 from '../assets/avatar3.png';
import ScrollReveal from '../Component/ScrollReveal';
import wokisentences from '../assets/wokisentences.png';
import RainbowAnimatedText from '../Component/RainbowAnimatedText';
import RainbowTextOutline from '../Component/RainbowTextOutline';

const About = () => {
  return (
    <div className="About">
      <div className="AboutWokiContainer">
        <ScrollReveal className="AboutWoki">
          <RainbowAnimatedText 
              text="Who's Woki" 
              className="AboutWokiTitle"
              style={{
                textShadow: `
                  calc(-0.033em) calc(-0.033em) 0 #000,
                  calc(0.033em) calc(-0.033em) 0 #000,
                  calc(-0.033em) calc(0.033em) 0 #000,
                  calc(0.033em) calc(0.033em) 0 #000
                `
              }}
            />
            
          <ScrollReveal className='AboutWokiText'>
            {/* <RainbowTextOutline text='Woki is an hermaphrodite frog who, after spending too much time on TikTok, transformed into `WOKI`' outlineColor="#000000" outlineWidth={2} />
            <RainbowTextOutline text='He identifies as a Pepe meme, dog, or cat depending on the seasonal hype. His pronouns are Pump/it, and he vowed to be the `Antichrist of the Solana Cabal`.'outlineColor="#000000" outlineWidth={2} />
            <RainbowTextOutline text='He is in several open relationships with Pepe, Brett and many more. He enjoys simple things in life, like protesting for vegetable rights or eating his own sht, the most sustainable food.'outlineColor="#000000" outlineWidth={2} />
            <RainbowTextOutline text='Be sure that he will bust your balls, you bunch of privileged heterosexual males.'outlineColor="#000000" outlineWidth={2} /> */}
            <div>He once was a traditionnal frog who, after spending too much time on Reddit, transformed into 'WOKI'</div>
            <div>He identifies as a dog or cat depending on the seasonal hype. His pronouns are Pump/it, and he vowed to be the 'Antichrist of the Solana Cabal'.</div>
            <div>He is in several open relationships with Pepe, Brett and more. He enjoys simple things in life, like protesting for vegetable rights or eating his own shit, the most sustainable food.</div>
            <div>Be sure that he will bust your balls, you bunch of white privileged heterosexual males.</div>
          </ScrollReveal>
          {/* <img className="AboutWokiImage" src={wokisentences} alt="wokisentences" /> */}
        </ScrollReveal>
      </div>
      <div className="AboutTeam">
        <div className="AboutTeamContainer">
          <ScrollReveal className="AboutTeamTitle">
            Magic Friends Team
          </ScrollReveal>
          <ScrollReveal className="AboutTeamImages">
              <div className='avatarContainer'>
                <img className="avatar" src={avatar1} alt="avatar1" />
                <div className="avatarName">Chaborz</div>
              </div>
            
            <div className='avatarContainer'>
              <img className="avatar" src={avatar2} alt="avatar2" />
              <div className="avatarName">Don Patchi</div>
            </div>
            <div className='avatarContainer'>
              <img className="avatar" src={avatar3} alt="avatar3" />
              <div className="avatarName">Kawaka</div>
            </div>
          </ScrollReveal>
        </div>
      </div>
    </div>
  );
}

export default About;