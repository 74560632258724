import React, { useState, useEffect, useRef } from 'react';
import './Roadmap.css';
import RainbowAnimatedText from '../Component/RainbowAnimatedText';
import ScrollReveal from '../Component/ScrollReveal';
import clickdots from '../assets/clickdots.png';

function Roadmap({ imgFile, popupData }) {
  const [popups, setPopups] = useState([]);
  const [activePopup, setActivePopup] = useState(null);
  const imgRef = useRef(null);
  const wrapperRef = useRef(null);

  useEffect(() => {
    const updatePopupPositions = () => {
      if (imgRef.current) {
        setPopups(popupData.map(popup => ({
          ...popup,
          left: `${popup.x * 100}%`,
          top: `${popup.y * 100}%`,
        })));
      }
    };

    updatePopupPositions();
    window.addEventListener('resize', updatePopupPositions);

    // Add click event listener to the document
    document.addEventListener('click', handleOutsideClick);

    return () => {
      window.removeEventListener('resize', updatePopupPositions);
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [popupData]);

  const handlePopupClick = (event, index) => {
    event.stopPropagation();
    setActivePopup(activePopup === index ? null : index);
  };

  const handleOutsideClick = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setActivePopup(null);
    }
  };

  return (
    <div className="Roadmap">
      <div className="RoadmapContentContainer">
        <ScrollReveal className="RoadmapTitle">
          <RainbowAnimatedText 
            text="Roadmap" 
            className="RoadmapTitleText"
            style={{
              textShadow: `
                calc(-0.03em) calc(-0.03em) 0 #000,
                calc(0.03em) calc(-0.03em) 0 #000,
                calc(-0.03em) calc(0.03em) 0 #000,
                calc(0.03em) calc(0.03em) 0 #000
              `
            }}
          />
          <img src={clickdots} alt="clickdots" className="clickdots"/>
        </ScrollReveal>
        <ScrollReveal className="ScrollRevealImageWrapper">
          <div className="ImageWrapper" ref={wrapperRef}>
            <img ref={imgRef} className='roadmapimg' src={imgFile} alt="Roadmap" />
            {popups.map((popup, index) => (
              <div
                key={index}
                className={`popup-trigger ${activePopup === index ? 'active' : ''}`}
                style={{
                  left: popup.left,
                  top: popup.top,
                  width: '2vw',
                  height: '2vw',
                }}
                onClick={(e) => handlePopupClick(e, index)}
              >
                <div className="popup-content">
                  {popup.content}
                </div>
              </div>
            ))}
          </div>
        </ScrollReveal>
      </div>
    </div>
  );
}

export default Roadmap;

  // const handleImageClick = (event) => {
  //   if (imgRef.current) {
  //     const rect = imgRef.current.getBoundingClientRect();
  //     const x = (event.clientX - rect.left) / rect.width;
  //     const y = (event.clientY - rect.top) / rect.height;
  //     console.log(`Clicked at x: ${x.toFixed(4)}, y: ${y.toFixed(4)}`);
  //   }
  // };


  {/* <img ref={imgRef} className='roadmapimg' src={imgFile} alt="Roadmap" onClick={handleImageClick}/> */}



