import React from 'react';

const RainbowButton = ({ children, onClick, className = '', style = {} }) => {
  return (
    <button onClick={onClick} className={`rainbow-button ${className}`} style={style}>
      {children}
      <style jsx>{`
        .rainbow-button {
          background: linear-gradient(to right, #FF0000, #FF8E00, #FFEE00, #008E00, #00C0FF, #8A2BE2);
          background-size: 200% 100%;
          background-position: 100% 0;
          color: black;
          text-align: center;
          text-decoration: none;
          color: black;
          font-size: 3.5vw;
          background-color: rgba(255, 99, 37, 1);
          padding: 1vw;
          padding-top: 1.4vw;
          width: 12vw;
          border-radius: 2vw;
          border: black 0.4vw solid;
          text-align: center;
          cursor: pointer;
          transition: all 0.3s ease;
          font-family: 'Bubblegum';
        }

        .rainbow-button:hover {
          background-position: 0 0;
        }

        .rainbow-button:focus {
          outline: none;
          box-shadow: 0 0 0 3px rgba(0,0,0,0.2);
        }

        @media screen and (orientation: portrait) {
          .rainbow-button {
            font-size: 3.5vh;
            padding: 1vh;
            padding-top: 1.4vh;
            width: 12vh;
            border-radius: 2vh;
            border: black 0.4vh solid;
          }
        }
      `}</style>
    </button>
  );
};

export default RainbowButton;