import React from 'react';
import Home from './Page/Home';
import Roadmap from './Page/Roadmap';
import RoadmapImg1 from './assets/roadmap.png';
import About from './Page/About';

function App() {
  const popupData = [
    { x: 0.48, y: 0.295, content: "Fork bitcoin to proof of love" },
    { x: 0.3565, y: 0.3397, content: "Woki as main character in a Disney movie" },
    { x: 0.3896, y: 0.3805, content: "Binance listing" },
    { x: 0.2420, y: 0.3860, content: "Wokechain the woke blockchain" },
    { x: 0.3896, y: 0.3805, content: "Establish Woke Nation (more commonly known as European Union)" },
    { x: 0.5659, y: 0.3562, content: "Castration gathering ritual of all Woki male holders" },
    { x: 0.4788, y: 0.4146, content: "Go Woki Get Rich on the Sphere" },
    { x: 0.6056, y: 0.4102, content: "Disable Sell Button" },
    { x: 0.7466, y: 0.4994, content: "Minority coins launchpad" },
    { x: 0.6056, y: 0.4939, content: "Publish the woke manual" },
    { x: 0.3885, y: 0.5810, content: "Solana validator running on 100% renewable energy" },
    { x: 0.2926, y: 0.4917, content: "Register the church of Wokes" },
    { x: 0.5306, y: 0.5501, content: "In support of women rights, Dev live cuts his testicles" },
    { x: 0.4833, y: 0.6350, content: "Campaign for Indian rights to jeet" },
    { x: 0.3422, y: 0.7088, content: "Cuck dev films his wife with a porn star" },
    { x: 0.4623, y: 0.7837, content: "Woki opens an OnlyFan account" },
    { x: 0.6628, y: 0.6934, content: "Woki at the next gaypride parade" },
    { x: 0.8182, y: 0.6625, content: "1 dollar charity donation to a Big Queer Club" },
  ];


  const rainbowGradient = `
  linear-gradient(
    to bottom,
    #FF0000 0%,
    #FF0000 5.5555%,
    #FF8E00 5.5555%,
    #FF8E00 11.1111%,
    #FFEE00 11.1111%,
    #FFEE00 16.6666%,
    #008E00 16.6666%,
    #008E00 22.2222%,
    #00C0FF 22.2222%,
    #00C0FF 27.7777%,
    #8A2BE2 27.7777%,
    #8A2BE2 33.3333%,
    #FF0000 33.3333%,
    #FF0000 38.8888%,
    #FF8E00 38.8888%,
    #FF8E00 44.4444%,
    #FFEE00 44.4444%,
    #FFEE00 50%,
    #008E00 50%,
    #008E00 55.5555%,
    #00C0FF 55.5555%,
    #00C0FF 61.1111%,
    #8A2BE2 61.1111%,
    #8A2BE2 66.6666%,
    #FF0000 66.6666%,
    #FF0000 72.2222%,
    #FF8E00 72.2222%,
    #FF8E00 77.7777%,
    #FFEE00 77.7777%,
    #FFEE00 83.3333%,
    #008E00 83.3333%,
    #008E00 88.8888%,
    #00C0FF 88.8888%,
    #00C0FF 94.4444%,
    #8A2BE2 94.4444%,
    #8A2BE2 100%
  )
`;

return (
  <div className="App">
    <Home />
    <Roadmap imgFile={RoadmapImg1} popupData={popupData}/>
    <About />
  </div>
);
}

export default App;
