import React, { useEffect, useRef } from 'react';
import './Home.css';
import TopLinks from '../Component/TopLinks';
import character from '../assets/character.png';
import RainbowText from '../Component/RainbowText';
import RainbowTextOutline from '../Component/RainbowTextOutline';
import RainbowButton from '../Component/RainbowButton';
import RainbowAnimatedText from '../Component/RainbowAnimatedText';

const Home = () => {
  return (
    <div className="Home">
      <TopLinks />
      <div className="HomeContentContainer">
        <div className="HomeContentImageContainer">
          <img className="HomeContentImage" src={character} alt="character" />
        </div>
        <div className="HomeContentDescription">
          <div className="HomeContentText">
            <div className="HomeContentTextTitle">
            <RainbowAnimatedText 
              text="Woki"
              className="title-rainbow-text" 
              style={{
                textShadow: `
                  calc(-0.025em) calc(-0.025em) 0 #000, 
                  calc(0.025em) calc(-0.025em) 0 #000, 
                  calc(-0.025em) calc(0.025em) 0 #000, 
                  calc(0.025em) calc(0.025em) 0 #000
                `
              }}
            />
            </div>
            <div className="HomeContentTextText">Your woke friend</div>
            <div className="HomeContentTextTextItalic">pronouns are Pump / It</div>
          </div>
          <a href="https://pump.fun/board" target="_blank" rel="noopener noreferrer">
            <RainbowButton className='HomeContentBuyButtonLink'>BUY</RainbowButton>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Home;
